<template>
  <div class="home">
    <v-sheet>
      <v-container>
        <Carousel/>
        <v-main>
          <v-row>
            <a id="About" :class="anchor"></a>
            <v-col cols="1" md="2"></v-col>
            <v-col cols="10" md="8">
              <h2 style="text-align: center">About</h2>
              <v-divider/>
              <About />
            </v-col>
            <!-- <v-col>
              <v-divider vertical/>
            </v-col>
            <a id="Gallery" :class="galleryAnchor"></a>
            <v-col  cols="12" sm="12" md="5">
              <h2>Gallery</h2>
                <Gallery />
            </v-col> -->
          </v-row>
          <v-row>
            <v-main>
                <a id="Amenities" :class="anchor"></a>
                <Amenities/>
            </v-main>
          </v-row>
          <v-row style="padding-bottom: 100px">
            <v-col cols="12">
              <v-btn
                  outlined
                  class="button"
                  to='/Contact'
              >
                Contact Us
              </v-btn>
            </v-col>
          </v-row>
        </v-main>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
    Carousel: () => import('../components/Carousel.vue'),
    // Gallery: () => import('../components/Gallery.vue'),
    About: () => import('../components/About.vue'),
    Amenities: () => import('../components/Amenities.vue')
  },
  computed: {
    anchor () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'xs-anchor'
        case 'sm': return 'sm-anchor'
        case 'md': return 'mdAndUp-anchor'
        case 'lg': return 'mdAndUp-anchor'
        case 'xl': return 'mdAndUp-anchor'
        default: return 'mdAndUp-anchor'
      }
    },
    galleryAnchor () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'xs-gallery-anchor'
        case 'sm': return 'sm-gallery-anchor'
        case 'md': return 'mdAndUp-gallery-anchor'
        case 'lg': return 'mdAndUp-gallery-anchor'
        case 'xl': return 'mdAndUp-gallery-anchor'
        default: return 'mdAndUp-gallery-anchor'
      }
    },
  }
}
</script>
<style scoped>
.button {
    align-self: center;
    font-style: italic;
    border-radius: 0;
}
.mdAndUp-gallery-anchor {
  position: absolute;
  top: -175px;
}
.sm-gallery-anchor {
  position: absolute;
  top: 370px;
}
.xs-gallery-anchor {
  position: absolute;
  top: 450px;
}

.mdAndUp-anchor {
  position: absolute;
  top: -175;
}
.sm-anchor {
  position: absolute;
  top: -125px;
}
.xs-anchor {
  position: absolute;
  top: -50px;
}
</style>